import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

const pageTitles = {
    // Auth
    '/inicio': { title: '' },
    '/auth/inicio-sesion': { title: '' },
    '/auth/registro': { title: 'Registro' },
    '/auth/recuperar-contraseña': { title: 'Recuperación de Contraseña' },
    '/auth/confirmar-correo': { title: 'Confirmación de Correo Electrónico' },
    // Inicio
    // '/inicio': { title: 'Inicio' },
    // Afiliados
    '/afiliados': {
        title: 'Afiliados',
    },
    '/transacciones/ok': {
        title: 'Transacciones Aprobadas',
    },
    '/transacciones/nok': {
        title: 'Transacciones No Aprobadas',
    },
    '/transacciones/detalle': {
        title: 'Detalle de transacción',
    },
    '/equipos': {
        title: 'Equipos',
    },
    '/solicitudes/equipos': {
        title: 'Solicitud de equipos',
    },
    '/solicitudes/mis-solicitudes': {
        title: 'Mis Solicitudes',
    },
    // Cuenta 
    '/cuenta': { title: 'Información de cuenta'},
    '/cuenta/informacion-general': {title: 'Información General'},
    // Claves de Instalación
    '/claves/generar-claves': { title: 'Claves de Instalación'},
    

};

@Injectable()
export class HeaderService {

    params: Array<string>;
    private titleSource = new BehaviorSubject({ title: null, description: null });
    title = this.titleSource.asObservable();

    constructor(private router: Router) {
        this.router.events.subscribe(route => {
            if (route instanceof NavigationEnd) {
                this.params = route.url.split('/');

                if (!isNaN(+this.params[this.params.length - 1])) {
                    route.url = route.url.slice(0, route.url.lastIndexOf('/'));
                }
                this.titleSource.next(pageTitles[route.url]);
            }
        })
    }

    addReportHeader(items: Array<any>) {
        items.forEach(item => {
            pageTitles[`/reportes/${item.name}`] = {
                title: item.description,
                // description: null
            };
        });
    }

    addLogHeader(items: Array<any>) {
        items.forEach(item => {
            pageTitles[`/bitacoras/${item.name}`] = {
                title: item.description,
                // description: null
            };
        });
    }
}

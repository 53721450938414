<div class="modal fade" bsModal #modal="bs-modal"
id="modalPasswordRequirements" tabindex="-1" aria-labelledby="passwordRequirementsModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="passwordRequirementsModalLabel">Requisitos de la Contraseña</h5>
          <button type="button" class="btn-close" (click)="hideModal()" data-bs-dismiss="modal" aria-label="Close"></button>
          
        </div>
        <div class="modal-body">
          <p>La contraseña debe contener al menos:</p>
          <ul>
            <li>Una letra mayúscula</li>
            <li>Una letra minúscula</li>
            <li>Un dígito</li>
            <li>Uno de los siguientes caracteres especiales: <br>
                @ $ ? ¡ - . ! &quot; _ + ( ) = , ; : &lt; &gt; {{'{'}} {{'}'}} | \\ / [ ] % &apos; &amp; * # ^ ~</li>
            <li>Debe tener al menos 8 caracteres de longitud</li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="hideModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
import { Component, OnInit, ViewChild } from '@angular/core';
import AbstractFormHolder from 'src/app/shared/forms/AbstractFormHolder';

@Component({
    selector: 'app-password-requirements',
    templateUrl: './password-requirements.component.html',
    styleUrls: ['./password-requirements.component.scss']
})
export class PasswordRequirementsComponent extends AbstractFormHolder implements OnInit {
    @ViewChild('modal') modal: any

    constructor() {

        super();
    }

    ngOnInit(): void {
    }

    showModal() {
        this.modal.show();
    }    
    
    hideModal() {
        this.modal.hide();
    }
}
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ItemMenuDefinition, ReportItemMenuDefinition } from 'src/app/models/item-menu';
import { Role } from 'src/app/models/auth/user';
import { UserService } from 'src/app/services/users/user.service';
import { UserInfo } from 'src/app/models/user';
import { skipWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable()
export class MenuService {

    menuItems: Array<any>;
    menuJson: Array<any>;
    reportItems: Array<any>;
    logItems: Array<any>;
    private user: UserInfo;
    affiliates: any[] = null;
   
    constructor(private http: HttpClient, private auth: AuthService, private userService: UserService) {

        this.menuItems = [];
    }

    addMenu(items: Array<ItemMenuDefinition>) {
        this.addMenuItems(items);
        this.addDynamicMenu();
    }

    addDynamicMenu() {

        return this.http
            .get<Array<ReportItemMenuDefinition>>(`${API_URL}/report`)
            .subscribe(data => {
                
                this.auth.affiliates$.subscribe(aff => 
                    this.affiliates = aff);

                const arrayUniqueByKey = data.map(item => item.groupName).filter((value, index, self) => self.indexOf(value) === index).sort();
                arrayUniqueByKey.forEach(itemGroup => {
                    if(!itemGroup)
                    {                
                        
                        const Reportes: ItemMenuDefinition[] = [];


                      const items: ItemMenuDefinition[] = [];
                        data.filter(d=>d.groupName == itemGroup).sort((a, b) => b.order-a.order).forEach(rpt => {
                        
                        const includeReport = !rpt.isMultipleEnabled || (this.affiliates.length > 1 && rpt.isMultipleEnabled);

                        if (includeReport) {  
                                                    
                                Reportes.push({
                                    text: rpt.description,
                                    id: 'mnu' + rpt.name,
                                    link: `/reportes/${rpt.name}`,
                                    icon: rpt.menuIcon,
                                    functionDescription: `${rpt.name}`,
                                    order: rpt.order
                                });

                           
                            }
                        });

                        this.addMenuItems(Reportes);

                    }  
                    else {
                        // this.menuItems = this.getMenu();
                        const jsonmenu$ = this.getMenuJson();
                        jsonmenu$.subscribe(json => {
                            
                            this.menuJson = json.menu;
                        
                            let menusChilds = this.menuJson.find(item => item.text == itemGroup);
                            if (!menusChilds)
                            {
                                menusChilds = {
                                    text: itemGroup,
                                    id: 'mnu' + itemGroup,
                                    link: `${itemGroup}`,
                                    icon: 'fas fa-layer-group',
                                    type: 'sub',
                                    submenu: []
                                };
						   } else {
                            // si existe el menu le elimina la funcion para que se muestre con este submenu si es que no posee permisos.
								menusChilds.function = '';
							}
                            
                            data.filter(d=>d.groupName == itemGroup).sort((a, b) => b.order-a.order).forEach(rpt => {
                                
                        
                                
                                const includeReport = !rpt.isMultipleEnabled || (this.affiliates.length > 1 && rpt.isMultipleEnabled);

                                if (includeReport)
                                {
                                    if (!menusChilds.submenu.find(subItem => subItem.id == 'mnu' + rpt.name))
                                    {
                                            menusChilds.submenu.push({
                                                text: rpt.description,
                                                id: 'mnu' + rpt.name,
                                                link: `/reportes/${rpt.name}`,
                                                icon: rpt.menuIcon ? rpt.menuIcon :'', // 'fas fa-file-invoice',
                                                functionDescription: `${rpt.name}`,
                                                order: rpt.order
                                            });
                                    }
                                } 
                                else {
                                    // quita la opcion no visualizable.
                                    const i = menusChilds.submenu.findIndex(e => e.id =='mnu' + rpt.name );
                                    i >= 0 ? menusChilds.submenu.splice(i, 1) : null;                           

                                }

                                 // Reportes.submenu.push(menusChilds);
                                this.addMenuItems([menusChilds]);
                            
                            }); //menuJson

                           
                        });


                    }
              });
              this.reportItems = data;
            //   this.addMenuItems([Reportes]);
          });
    }

    private addMenuItems(items: Array<ItemMenuDefinition>) {

        const role = this.auth.getRole;

        this.userService.getCurrentUser().subscribe(user => {
            this.user = user;
       
        });

        items.sort((a, b) => a.order && b.order ? a.order - b.order : 0);
        items.forEach(item => {
            if (item.submenu) {

                item.submenu.sort((a, b) => a.order && b.order ? a.order - b.order : 0);
                item.submenu.forEach(subitem => {

                    if (subitem.submenu) {

                        subitem.submenu.sort((a, b) => a.order && b.order ? a.order - b.order : 0);
                    }
                });
            }
        });

        items.forEach((itemMenu) => {
            if (role) {

                if (this.AddMenu(itemMenu, role)) {

                    if (itemMenu.submenu) {

                        if (itemMenu.function && !role.functions.includes(itemMenu.function)) {

                            return;
                        }
                        
                        const auxSubMenu = JSON.parse(JSON.stringify(itemMenu.submenu));
                        itemMenu.submenu.forEach(submenu => {

                            if (!this.AddMenu(submenu, role)) {
                                const i = auxSubMenu.findIndex(e => JSON.stringify(e) === JSON.stringify(submenu));
                                i >= 0 ? auxSubMenu.splice(i, 1) : null;
                            }
                        });

                        itemMenu.submenu = auxSubMenu;
                    }

                    if (itemMenu.submenu && itemMenu.submenu.length === 0) {

                        return;
                    }

                    // valida si existe el item de menu para reemplazarlo o agregarlo
                    const auxMenu = JSON.parse(JSON.stringify(this.menuItems));
                    const i = auxMenu.findIndex(e => e.id === itemMenu.id);
                    i>=0 ? this.menuItems.splice(i, 1, itemMenu) : this.menuItems.push(itemMenu);
               
                }
            }
        });
        

        this.reorderMenu();
    }

    getMenu() {

        return this.menuItems;
    }

    getMenuJson() {

        return this.http.get<any>('assets/menu/menu.json');
    }

    setMenu(menu: Array<ItemMenuDefinition>) {

        this.menuItems = menu;
    }

    private reorderMenu() {

        this.menuItems.sort((a, b) => a.order && b.order ? a.order - b.order : 0);
    }

    private AddMenu(itemMenu: ItemMenuDefinition, role: Role): Boolean {

        return !itemMenu.function || role.functions?.includes(itemMenu.function);
    }
}
